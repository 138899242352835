







import { Component, Vue } from 'vue-property-decorator'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import ProductRecommend from '@/components/ProductRecommend.vue'
import ProductRemind from '@/components/ProductRemind.vue'

@Component({
  components: {
    LayoutDefault,
    ProductRecommend,
    ProductRemind
  }
})
export default class Suggestion extends Vue {}
